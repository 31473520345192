import { useCreateAssociatePackaging, useFetchProject } from "../../hooks";
const useGetPackagingSystemDataForm = ({
  projectId,
  taskId,
  ownerCompanyId,
  manufacturedItemId,
  languageCode,
  setLoading,
}) => {
  const {
    id,
    fetchProject,
    fetchTask,
    packagingSystems,
    packagingSystemRelation,
    taskValue: {
      form: { formBlocks },
    },
  } = useFetchProject({
    taskId,
    projectId,
    languageCode,
    ownerCompanyId,
    manufacturedItemId,
  });

  const { handleOnTemporaryAssociate, refreshProject } =
    useCreateAssociatePackaging({
      id,
      taskId,
      setIsSaving: () => {},
      packagingSystems,
      fetchProject,
      fetchTask,
      formBlocks,
      onRefreshProject: () => {},
      manufacturedItemId,
      packagingSystemToProductRelations: packagingSystemRelation,
      setLoading,
    });

  return {
    handleOnTemporaryAssociate,
    refreshProject,
    formBlocks,
  };
};

export default useGetPackagingSystemDataForm;
