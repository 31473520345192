import { Title } from "@trace-one/react-components";
import BryntumTableOrGantt from "./components/BryntumTableOrGantt";
import { useIntl } from "react-intl";
import { planningSectionTitle } from "routes/Projects/defineMessages";

export const Planning = ({ shouldrefresh = false, setRefresh }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Title
        level={2}
        title={formatMessage(planningSectionTitle.title)}
        subtitle={formatMessage(planningSectionTitle.subtitle)}
      />
      <BryntumTableOrGantt
        shouldrefresh={shouldrefresh}
        setRefresh={setRefresh}
      />
    </>
  );
};
