import {
  CREATE_TEAM,
  RESET,
  SAVE_TEAM_SUCCESS,
  DELETE_TEAM_SUCCESS,
  DUPLICATE_TEAM_SUCCESS,
  EDIT_TEAM_SUCCESS,
  SET_TEAMS,
  SET_USERS,
  SET_TEAMS_LOADING,
} from "../../constants";

export const SET_IS_TEAM_DATA_CHANGED = "SET_IS_TEAM_DATA_CHANGED";

export const initialState = {
  teams: [],
  team: [],
  users: [],
  isTeamSaved: false,
  isTeamDeleted: false,
  isTeamDuplicated: false,
  isTeamEdited: false,
  isTeamsLoading: false,
  isTeamTabValuesChanged: false,
};

export const setTeamDataChanged = payload => ({
  type: SET_IS_TEAM_DATA_CHANGED,
  payload,
});

export default function teamsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_TEAMS:
      return {
        ...state,
        teams: payload,
      };
    case CREATE_TEAM:
      return {
        ...state,
        team: payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: payload,
      };
    case SAVE_TEAM_SUCCESS:
      return {
        ...state,
        isTeamSaved: payload,
      };
    case SET_IS_TEAM_DATA_CHANGED:
      return {
        ...state,
        isTeamTabValuesChanged: payload,
      };
    case SET_TEAMS_LOADING:
      return {
        ...state,
        isTeamsLoading: payload,
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        isTeamDeleted: payload,
      };
    case DUPLICATE_TEAM_SUCCESS:
      return {
        ...state,
        isTeamDuplicated: payload,
      };
    case EDIT_TEAM_SUCCESS:
      return {
        ...state,
        isTeamEdited: payload,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
