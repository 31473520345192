import {
  SET_BRYNTUM_DATA,
  SET_BRYNTUM_USER_RESPONSIBILITIES,
  SET_BRYNTUM_IS_LOADING,
  portalTypeId,
} from "../../../constants";
import { projectApi, referenceListApi } from "apis";

export const setIsBryntumLoading = payload => {
  return {
    type: SET_BRYNTUM_IS_LOADING,
    payload,
  };
};

export const setBryntumUserResponsibilities = payload => {
  return {
    type: SET_BRYNTUM_USER_RESPONSIBILITIES,
    payload,
  };
};

export const setBryntumData = payload => {
  return {
    type: SET_BRYNTUM_DATA,
    payload,
  };
};

function getResponsibilitiesIds(responsibilities) {
  const result = responsibilities.reduce(
    (acc, item) => acc.concat(item.responsibilityIds),
    []
  );
  return result;
}

export const fetchBryntumData = ({
  projectId,
  projectTypeId,
  languageCode,
}) => {
  return async dispatch => {
    try {
      const { getResponsibilitiesByProjectTypeId, getProjectById } = projectApi;
      dispatch(setIsBryntumLoading(true));
      const {
        data: { responsibilities },
      } = await getResponsibilitiesByProjectTypeId({ projectTypeId });

      const {
        data: { bryntumProject, ownerCompanyId, hasLockableTask },
      } = await getProjectById({
        projectId,
        languageCode,
      });
      const { data: userResponsibilities } =
        await referenceListApi.getItemsCollections({
          languageCode,
          portalTypeId,
          idCollection: getResponsibilitiesIds(responsibilities),
          companyId: ownerCompanyId,
        });
      dispatch(setBryntumData({ ...bryntumProject, hasLockableTask }));
      dispatch(setBryntumUserResponsibilities(userResponsibilities));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setIsBryntumLoading(false));
    }
  };
};
