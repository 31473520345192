import {
  SET_TREE_PRODUCT_CATEGORIES,
  SET_TRADE_ITEMS,
  HANDLE_CHANGE_FILTER,
  SET_PRODUCT_BRANDS,
  SET_IS_PRODUCT_BRANDS_LOADING,
  SET_IS_TREE_CATEGORIES_LOADING,
  SET_VALIDATED_PRODUCTS,
  REMOVE_VALIDATED_PRODUCT,
  IS_CATEGORIES_FOR_VALIDATED_PRODCTS_LOADING,
  SET_CATEGORIES_FOR_VALIDATED_PRODCTS,
  SET_SELECTED_PRODUCTS_CURRENT_AND_TOTAL,
  SET_SUPPLIERS,
} from "../../../constants";
import { productApi, cumdApi, referenceListApi } from "apis";

export const setTreeProductCategories = payload => {
  return {
    type: SET_TREE_PRODUCT_CATEGORIES,
    payload,
  };
};

export const setTradeItems = payload => {
  return {
    type: SET_TRADE_ITEMS,
    payload,
  };
};

export const getAugmentedManufacturedItems = async manufacturedItems => {
  // get the product and supplier data associated with a manufactured item via its tradeItemId

  const tradeItemIds = manufacturedItems?.length && [
    ...new Set(manufacturedItems.map(({ tradeItemId }) => tradeItemId)),
  ];

  if (tradeItemIds?.length) {
    const suppliers = await fetchAssociatedSuppliers({
      manufacturedItems,
    });

    const { data: products } = await productApi.getTradeItemsCollections({
      idCollection: tradeItemIds,
    });
    let augmentedManufacturedItems = manufacturedItems.map(item => {
      const [{ companyDisplayName: supplierCompanyName } = {}] =
        suppliers.filter(
          supplier => supplier.companyId === item.supplierCompanyId
        );

      const [product] = products.filter(
        product => item.tradeItemId === product.id
      );

      return {
        ...product,
        ...item,
        ...{ supplierCompanyName, isManufacturedItem: true },
      };
    });

    return augmentedManufacturedItems;
  }

  return [];
};

export const fetchSuppliers = ({ companyId }) => {
  return async dispatch => {
    try {
      const {
        data: { companies },
      } = await cumdApi.getCompaniesByFilters({
        companyActivityId: 2,
        relatedOwnerCompanyId: companyId,
      });

      dispatch(setSuppliers(companies));
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchAssociatedSuppliers = async ({
  manufacturedItems: items,
}) => {
  if (items?.length === 0) return [];
  const supplierIds = items?.length && [
    ...new Set(items.map(({ supplierCompanyId }) => supplierCompanyId)),
  ];

  try {
    const { data: companies } = await cumdApi.getPublicCompanies({
      companyIds: supplierIds,
    });

    return companies;
  } catch (error) {
    console.error(error);
  }
};

export const setSuppliers = payload => {
  return {
    type: SET_SUPPLIERS,
    payload,
  };
};

export const setTotalAndCurrent = payload => {
  return {
    type: SET_SELECTED_PRODUCTS_CURRENT_AND_TOTAL,
    payload,
  };
};

export const setFilters = payload => {
  return {
    type: HANDLE_CHANGE_FILTER,
    payload,
  };
};

export const fetchTreeProductCategories = ({ languageCode, companyId }) => {
  return async dispatch => {
    dispatch(setIsTreeCategoriesLoading(true));
    try {
      const { data: categories } = await referenceListApi.getCategorylists({
        companyId,
      });

      const fetchTreeSubCategories = async id => {
        const { data: subCategories } =
          await referenceListApi.getCategorylistsHierarchyById({
            categoryId: id,
            languageCode,
          });

        return subCategories.map(subCategory => ({
          id: subCategory.categoryItemId,
          categoryListId: id,
          key: subCategory.categoryItemId,
          pId:
            subCategory.parent1CategoryItemId ||
            subCategory.parent2CategoryItemId ||
            subCategory.parent3CategoryItemId ||
            subCategory.parent4CategoryItemId ||
            id,
          value: subCategory.categoryItemId,
          title: subCategory.categoryItemName,
          isLeaf: !subCategories.some(
            d =>
              d.parent4CategoryItemId === subCategory.categoryItemId ||
              d.parent3CategoryItemId === subCategory.categoryItemId ||
              d.parent2CategoryItemId === subCategory.categoryItemId ||
              d.parent1CategoryItemId === subCategory.categoryItemId
          ),
        }));
      };
      const getTreeCategories = async () => {
        let treeCategoriesFull = [];
        for (const category of categories) {
          const subCategories = await fetchTreeSubCategories(
            category.categoryListId
          );
          if (subCategories.length > 0) {
            treeCategoriesFull = [
              ...treeCategoriesFull,
              {
                id: category.categoryListId,
                pId: 0,
                value: category.categoryListId,
                title: category.categoryListName,
                selectable: false,
              },
              ...subCategories,
            ];
          }
        }
        return treeCategoriesFull;
      };
      const treeCategories = await getTreeCategories();
      dispatch(setTreeProductCategories(treeCategories));
      dispatch(setIsTreeCategoriesLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setIsTreeCategoriesLoading(false));
    }
  };
};

export const fetchProductBrands = ({ companyId }) => {
  return async dispatch => {
    dispatch(setIsProductBrandsLoading(true));
    try {
      const { data: productBrands } = await productApi.getBrands({
        ownerCompanyId: companyId,
      });

      dispatch(setProductBrands(productBrands));
      dispatch(setIsProductBrandsLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setIsProductBrandsLoading(false));
    }
  };
};

export const setProductBrands = payload => {
  return {
    type: SET_PRODUCT_BRANDS,
    payload,
  };
};

export const setIsTreeCategoriesLoading = payload => {
  return {
    type: SET_IS_TREE_CATEGORIES_LOADING,
    payload,
  };
};

export const setIsProductBrandsLoading = payload => {
  return {
    type: SET_IS_PRODUCT_BRANDS_LOADING,
    payload,
  };
};

export const setValidatedProducts = payload => {
  return {
    type: SET_VALIDATED_PRODUCTS,
    payload,
  };
};

export const removeValidatedProduct = payload => {
  return {
    type: REMOVE_VALIDATED_PRODUCT,
    payload,
  };
};

export const isCategoriesForValidatedProductsLoading = payload => {
  return {
    type: IS_CATEGORIES_FOR_VALIDATED_PRODCTS_LOADING,
    payload,
  };
};

export const setCategoriesForValidatedProducts = payload => {
  return {
    type: SET_CATEGORIES_FOR_VALIDATED_PRODCTS,
    payload,
  };
};

// TODO rename- getCategoryItemIdCollectionFromProducts
export const idCollectionFromProducts = products => {
  return products
    .filter(
      eachProduct => eachProduct.category && eachProduct.category.categoryItemId
    )
    .map(eachProduct => eachProduct.category.categoryItemId);
};

export const fetchCategoriesForValidatedProducts = ({ languageCode, data }) => {
  return async dispatch => {
    try {
      dispatch(isCategoriesForValidatedProductsLoading(true));
      const { data: netContentUnits } =
        await referenceListApi.getCategoryitemsCollections({
          languageCode,
          idCollection: idCollectionFromProducts(data),
        });
      dispatch(setCategoriesForValidatedProducts(netContentUnits));
      dispatch(isCategoriesForValidatedProductsLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(isCategoriesForValidatedProductsLoading(false));
    }
  };
};
