/* istanbul ignore file */
import moment from "moment-business-days";
import { statusNames } from "constants/tasks";
import { statusNames as statusNamesProject } from "constants/projects";
import { MutableRefObject } from "react";

interface HardDateConstraint {
  EndDate?: boolean;
  StartDate?: boolean;
  Duration?: boolean;
}

interface TaskOriginalData {
  id: string;
  hardDateConstraint?: HardDateConstraint;
  isStep?: boolean;
  isUserTask?: boolean;
  name: string;
  isTimerTask?: boolean;
}

interface Task {
  task: {
    originalData: TaskOriginalData;
    endDate: string;
  };
  originalData?: TaskOriginalData;
  endDate?: string;
  dueDateUtc: string;
}

export interface SetStartDateUtilFuncParams {
  ganttRef: MutableRefObject<any>;
  startDate: Date;
  onChange?: (data: { startDate: string; endDate: string }) => void;
}

export interface GanttPayload {
  startDateUtc: string;
  endDateUtc: string;
  tasks: GanttPayloadTask[];
}

interface GanttPayloadTask {
  taskId: string;
  durationInDays: number;
  fixedEndDate: string | null;
}

const displayFutureTaskConditions = originalData => {
  return (
    originalData?.stepStatus !== "Future" &&
    originalData?.taskStatus !== "Future"
  );
};
const displayMyTaskConditions = (originalData, config) => {
  return (
    originalData.userId &&
    originalData.userId === config?.userId &&
    !originalData.notRelevantForUser
  );
};

export const wait = (delay = 0) => {
  return new Promise(resolve => {
    window.setTimeout(resolve, delay);
  });
};

export function createBryntumFilter(
  displayMyTasks: boolean,
  showFutureTasks: boolean,
  config: any
) {
  return function bryntumFilter(task) {
    const { originalData } = task;
    switch (true) {
      case showFutureTasks && !displayMyTasks:
        return !originalData.notRelevantForUser;
      case showFutureTasks && displayMyTasks:
        return displayMyTaskConditions(originalData, config);
      case !showFutureTasks && displayMyTasks:
        return (
          displayMyTaskConditions(originalData, config) &&
          displayFutureTaskConditions(originalData)
        );
      default:
        return (
          displayFutureTaskConditions(originalData) &&
          !originalData.notRelevantForUser
        );
    }
  };
}

export const setStartDate = async ({
  ganttRef,
  startDate,
  onChange,
}: SetStartDateUtilFuncParams) => {
  if (startDate && ganttRef.current?.project) {
    await ganttRef.current.project.setStartDate(startDate);

    // This code is here to facilitate select of past date in estimated end date calender and move project start to past
    const updateTaskDates = (task, projectStartDate: Date, index?: Number) => {
      task.manuallyScheduled = false;
      task.setStartDate(projectStartDate);
      if (index === 0) {
        task.setConstraintType("startnoearlierthan");
        task.setConstraintDate(projectStartDate);
        task.setDirection(null);
      } else {
        task.setConstraintType(null);
        task.setConstraintDate(null);
        task.setDirection(null);
      }
      if (task.children && Array.isArray(task.children)) {
        task.children.forEach(child =>
          updateTaskDates(child, projectStartDate)
        );
      }
    };
    ganttRef?.current?.project?.tasks.forEach((task, index) => {
      updateTaskDates(task, startDate, index);
    });

    if (onChange) {
      const duration = ganttRef.current.project.duration;
      let newEndDate = moment(startDate);
      for (let i = 0; i < duration; i++) {
        newEndDate = newEndDate.nextBusinessDay();
      }

      onChange({
        startDate: startDate.toISOString(),
        endDate: newEndDate.toDate().toISOString(),
      });
    }
  }
};

export const setEndDate = async ({
  endDate,
  ganttRef,
}: {
  endDate: Date;
  ganttRef: MutableRefObject<any>;
}) => {
  if (endDate && ganttRef.current?.project) {
    const endDateMoment = moment(endDate);
    const duration = ganttRef.current.project.duration;
    let newStartDate = endDateMoment.clone();
    for (let i = 0; i < duration; i++) {
      newStartDate = newStartDate.prevBusinessDay();
    }
    await ganttRef.current.project.setEndDate(endDateMoment.toDate());
    return setStartDate({
      ganttRef,
      startDate: newStartDate.toDate(),
    });
  }
};

function isWeekday(date: Date) {
  const day = date.getDay();
  return day !== 0 && day !== 6;
}

export const getUpdatedStartDate = (startDate: Date, durationGap: number) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  let currentDate = new Date(startDate);
  const direction = durationGap > 0 ? 1 : -1;
  let remainingDays = Math.abs(durationGap);

  while (remainingDays > 0) {
    currentDate.setTime(currentDate.getTime() + direction * millisecondsPerDay);
    if (isWeekday(currentDate)) remainingDays--;
  }

  return currentDate;
};

export const getDaysInBetween = (oldValue: Date, newValue: Date) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  let differenceInDays = 0;
  const direction = newValue > oldValue ? 1 : -1;
  let currentDate = new Date(oldValue);

  while (currentDate.getTime() !== newValue.getTime()) {
    currentDate.setTime(currentDate.getTime() + direction * millisecondsPerDay);
    if (isWeekday(currentDate)) differenceInDays += direction;
  }

  return differenceInDays;
};

export function getFixedEndDateOnDurationChange(task) {
  const {
    originalData: {
      estimatedEndDateCheckBox,
      fixedEndDate,
      hardDateConstraint,
    },
  } = task;

  if (
    estimatedEndDateCheckBox ||
    (fixedEndDate && hardDateConstraint?.EndDate)
  ) {
    const selectedEndDate = task.originalData?.estimatedEndDateSelection;
    const fixedEndDate = selectedEndDate
      ? new Date(selectedEndDate)
      : task.endDate;
    const startDate = new Date(task.project.startDate);
    return syncTimeWithStartDate(startDate, fixedEndDate);
  }

  return null;
}

const updateDurationValue = task => {
  const duration = task?.duration;

  if (task?.notRelevantForUser || duration == null) {
    return 0;
  }

  if (task.isTimerTask && duration < 1) {
    return 0;
  }

  return duration < 1 ? 1 : Math.round(duration);
};

export const getAPIReadyInfoFromTasks = (
  tasks,
  current = [],
  options = { isProjectOwner: false, projectStatus: statusNamesProject.Draft }
) => {
  const { isProjectOwner, projectStatus } = options;
  let newCurrent = [...current];
  for (const task of tasks) {
    if (
      canChangeDuration(
        {
          taskStatus: task.originalData.taskStatus,
        },
        projectStatus,
        isProjectOwner
      )
    ) {
      newCurrent = [
        ...newCurrent,
        {
          taskId: task.originalData.id,
          durationInDays: updateDurationValue(task),
          fixedEndDate: getFixedEndDateOnDurationChange(task),
        },
      ];
    }
    if (task.children) {
      newCurrent = [
        ...getAPIReadyInfoFromTasks(task.children, newCurrent, options),
      ];
    }
  }
  return newCurrent.reduce((acc, item) => {
    if (!acc.find(x => x.taskId === item.taskId)) {
      return [...acc, item];
    }
    return acc;
  }, []);
};

const canChangeDuration = (
  { taskStatus },
  projectStatus = statusNamesProject.Draft,
  isProjectOwner = false
) => {
  if (
    taskStatus !== statusNames.InProgressOneWord &&
    taskStatus !== statusNames.Future &&
    taskStatus !== "Undefined"
  ) {
    return false;
  }
  if (!isProjectOwner) {
    return false;
  }

  return !(
    projectStatus !== statusNamesProject.InProgress &&
    projectStatus !== statusNamesProject.Draft &&
    projectStatus !== statusNamesProject.Scheduled
  );
};

export const canResizeTask =
  (projectStatus, isProjectOwner: boolean) =>
  ({
    taskRecord: {
      originalData: { taskStatus },
    },
  }) => {
    return canChangeDuration({ taskStatus }, projectStatus, isProjectOwner);
  };

export const canUpdateCell =
  (projectStatus, isProjectOwner: boolean) =>
  ({
    editorContext: {
      record: {
        originalData: { taskStatus },
      },
      column: {
        originalData: { uuid },
      },
    },
  }) => {
    if (uuid === "duration") {
      return canChangeDuration(
        {
          taskStatus,
        },
        projectStatus,
        isProjectOwner
      );
    }
    return true;
  };

export const zoomToFit = ({ ganttRef }): void => {
  if (ganttRef?.current?.zoomToFit) {
    ganttRef.current.zoomToFit({
      leftMargin: 30,
      rightMargin: 50,
    });
  }
};

export const getExpandColapseInfoFromStore = (): Record<
  string,
  boolean
> | null => {
  const store = window.sessionStorage;
  const data = store.getItem("expandcollapse");
  try {
    const parsedData = JSON.parse(data);
    if (parsedData && Object.keys(parsedData).length > 0) {
      return parsedData;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const storeExpandCollapseInfo = (
  node: string,
  collapsed: boolean
): void => {
  const store = window.sessionStorage;
  const data = getExpandColapseInfoFromStore();
  const newData = { ...data, [node]: collapsed };
  store.setItem("expandcollapse", JSON.stringify(newData));
};

export const deleteExpandCollapseInfo = (): void => {
  const store = window.sessionStorage;
  store.removeItem("expandcollapse");
};

const getProjectIDFromURL = (url = ""): string =>
  url.split("projectfolder/").pop()?.split("/")[0];

export const setProjectIDFromURL = (url = ""): string | void => {
  const projectID = getProjectIDFromURL(url);
  if (projectID) {
    sessionStorage.setItem("projectID", projectID);
  }
};

export const areWeStillInSameProject = (url = ""): boolean => {
  const storedProjectId = sessionStorage.getItem("projectID");
  const currentProjectId = getProjectIDFromURL(url);

  if (!currentProjectId) {
    return false;
  }

  return currentProjectId === storedProjectId;
};

export const syncTimeWithStartDate = (
  startDate: Date,
  selectedDate: Date
): Date => {
  const resultDate = new Date(selectedDate);
  resultDate.setHours(startDate.getHours());
  resultDate.setMinutes(startDate.getMinutes());
  resultDate.setSeconds(startDate.getSeconds());
  resultDate.setMilliseconds(startDate.getMilliseconds());

  return resultDate;
};

export const syncTimeWithPreviousValue = syncTimeWithStartDate;

export const areDatesEqual = (date1: Date, date2: Date): boolean => {
  return (
    date1?.getFullYear() === date2?.getFullYear() &&
    date1?.getMonth() === date2?.getMonth() &&
    date1?.getDate() === date2?.getDate()
  );
};

const checkIfFromTasksOverlappingLockedTask = (fromTasks, lockedTask) => {
  return fromTasks.some(task => {
    return new Date(task?.endDate) > new Date(lockedTask?.startDate);
  });
};

const getToUserTasks = ({ project, fromTask }) => {
  const dependencies = project?.dependencies?.filter(
    dep => dep?.fromEvent?.id === fromTask?.id
  );

  // for handling last userTask
  if (dependencies && !dependencies.length) return [];

  const dependenciesTo = dependencies?.map(dep => dep?.toEvent);

  const userTasks = dependenciesTo?.filter(task => task?.isUserTask);
  const nonUserTasks = dependenciesTo?.filter(task => !task?.isUserTask);

  const recursiveUserTasks = nonUserTasks?.flatMap(task =>
    getToUserTasks({ project, fromTask: task })
  );

  return [...userTasks, ...recursiveUserTasks];
};

const getFromUserTasks = ({ project, toTask }) => {
  const dependencies = project?.dependencies?.filter(
    dep => dep?.toEvent?.id === toTask?.id
  );

  // for handling 1st userTask
  if (dependencies && !dependencies.length) return [];

  const dependenciesFrom = dependencies?.map(dep => dep?.fromEvent);

  const userTasks = dependenciesFrom?.filter(task => task?.isUserTask);
  const nonUserTasks = dependenciesFrom?.filter(task => !task?.isUserTask);

  const recursiveUserTasks = nonUserTasks?.flatMap(task =>
    getFromUserTasks({ project, toTask: task })
  );

  return [...userTasks, ...recursiveUserTasks];
};

export const checkHardDateConstraintError = (
  tasks: Task[],
  project
): boolean => {
  if (!Array.isArray(tasks)) return false;

  const lockedTask = tasks.find(
    task => task?.originalData?.hardDateConstraint?.EndDate
  );
  if (!lockedTask) {
    return false;
  }
  const fromUserTasks = getFromUserTasks({
    project: project,
    toTask: lockedTask,
  });

  const { hardDateConstraint } = lockedTask.originalData || {};

  if (hardDateConstraint?.EndDate) {
    const isError = fromUserTasks.length // for handling 1st userTask
      ? checkIfFromTasksOverlappingLockedTask(fromUserTasks, lockedTask)
      : false;
    return isError;
  }

  return false;
};

export function checkHardDateConstraint(tasks = []): boolean {
  for (const task of tasks) {
    if (task?.hardDateConstraint?.EndDate) {
      return true;
    }
    if (task?.children?.length > 0) {
      if (checkHardDateConstraint(task.children)) {
        return true;
      }
    }
  }
  return false;
}

export const isGanttPayloadEqual = (
  localPayload: GanttPayload,
  storedPayload: GanttPayload
) => {
  const formatDate = (dateString: string) => dateString.split("T")[0];
  if (
    formatDate(localPayload.endDateUtc) !==
      formatDate(storedPayload.endDateUtc) ||
    formatDate(localPayload.startDateUtc) !==
      formatDate(storedPayload.startDateUtc)
  ) {
    return false;
  }

  const taskMap = new Map();
  localPayload.tasks.forEach(task => taskMap.set(task.taskId, [task]));
  storedPayload.tasks.forEach(task =>
    taskMap.set(task.taskId, [...taskMap.get(task.taskId), task])
  );

  return [...taskMap.values()].every(([task1, task2]) => {
    return JSON.stringify(task1) === JSON.stringify(task2);
  });
};

const getLockedTask = (ganttPayload: GanttPayload) => {
  return ganttPayload?.tasks?.find(task => task.fixedEndDate);
};

export const isAnyTaskLocaked = (ganttPayload: GanttPayload) => {
  return !!getLockedTask(ganttPayload)?.taskId;
};

export const isDurationChanged = (
  storedGanttPayload: GanttPayload,
  generatedGanttPayload: GanttPayload
): GanttPayloadTask | null => {
  if (!storedGanttPayload?.tasks || !generatedGanttPayload?.tasks?.length) {
    return null;
  }

  const taskDurationMap = new Map(
    generatedGanttPayload?.tasks.map(task => [
      task?.taskId,
      task?.durationInDays || 0,
    ])
  );

  const durationChangedItem = storedGanttPayload.tasks.find(task => {
    return task.durationInDays !== taskDurationMap.get(task.taskId);
  });

  return durationChangedItem;
};

export const isDurationChangedTaskPresentBeforeLockedTask = (
  task: GanttPayloadTask | null,
  ganttPayload: GanttPayload
) => {
  if (!task) {
    return false;
  }

  const lockedTask = getLockedTask(ganttPayload);
  if (!lockedTask) {
    return false;
  }

  const taskIds = ganttPayload.tasks?.map(t => t.taskId);
  const changedTaskIndex = taskIds.indexOf(task.taskId);
  const lockedTaskIndex = taskIds.indexOf(lockedTask.taskId);
  return changedTaskIndex < lockedTaskIndex;
};

export function isStartDateLesserThenToday(dateString: string) {
  if (dateString) {
    const givenDate = new Date(dateString);
    const today = new Date();

    const todayDateOnly = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const givenDateOnly = new Date(
      givenDate.getFullYear(),
      givenDate.getMonth(),
      givenDate.getDate()
    );

    return todayDateOnly > givenDateOnly;
  }
}

const configureSiblingTasks = (project, task) => {
  //dependency from user tasks
  const fromUserTasks = getFromUserTasks({ project: project, toTask: task });

  fromUserTasks.forEach(fromUserTask => {
    const dependencies = project?.dependencies?.filter(
      dep => dep?.toEvent?.id === fromUserTask?.id
    );
    if (!dependencies.length) {
      //handling direction 1st user task
      const firstTaskIndex = project?.tasks.findIndex(
        task => task.id === fromUserTask.id
      );
      const firstTaskParent = project?.tasks[firstTaskIndex - 1];
      setBackwardDirectionForTask(firstTaskParent);
    }
    fromUserTask.setConstraintType("finishnolaterthan");
    fromUserTask.setConstraintDate(task.startDate);
  });
  // locked task sibling task
  const siblingTasks = fromUserTasks.flatMap(fromTask => {
    const toUserTasks = getToUserTasks({
      project: project,
      fromTask: fromTask,
    });
    return toUserTasks;
  });

  // setting constraints for sibling tasks
  siblingTasks.forEach(sibling => {
    if (sibling.id !== task.id) {
      sibling.setConstraintType("muststarton");
      sibling.setConstraintDate(task.startDate);
    }
  });
};

const setInitialConstraints = task => {
  task.setConstraintType(null);
  task.setConstraintDate(null);
  task.setDirection("Forward");
};

const lockTask = task => {
  task.setConstraintType("muststarton");
  task.setConstraintDate(task.startDate);
};

const setBackwardDirectionForTask = task => {
  task.setConstraintType(null);
  task.setConstraintDate(null);
  task.setDirection("Backward");
};

const handleTaskLocking = (task, isLockedTask, checked, lockedTaskReached) => {
  if (isLockedTask) {
    if (checked) {
      lockTask(task);
      //fixing all start dates of siblisg tasks of the locked task
      configureSiblingTasks(task?.project, task);

      //fixing start date of all child tasks of the locked task and removing all dependency only from the locked task to child tasks
      const toUserTasks = getToUserTasks({
        project: task?.project,
        fromTask: task,
      });
      toUserTasks.forEach(childTask => {
        childTask.setConstraintType("startnoearliarthan");
        childTask.setConstraintDate(task.endDate);
      });
    } else {
      setInitialConstraints(task);
    }
  } else {
    if (checked) {
      if (!lockedTaskReached) {
        setBackwardDirectionForTask(task);
      } else {
      }
    } else {
      setInitialConstraints(task);
    }
  }
};

export const processTaskLocking = (tasks, lockedTaskId, target, ganttRef) => {
  const { checked } = target;
  let lockedTaskReached = false;

  tasks.forEach(task => {
    const { id: taskId, isUserTask } = task.originalData;

    if (isUserTask) {
      handleTaskLocking(
        task,
        taskId === lockedTaskId,
        checked,
        lockedTaskReached
      );

      if (taskId === lockedTaskId) {
        lockedTaskReached = true;
      }
    }
  });
};
