import { isEmpty } from "lodash";

// API calls
import {
  cumdApi,
  discussionApi,
  documentApi,
  projectApi,
  referenceListApi,
} from "apis";

// Utils
import { sortLatestByLockedStatus } from "utils/general";
import { addDiscussionIdToLockedFiles } from "./general";

const PRODUCT_DOCUMENT_TYPES = "a2084d73-7bfe-45dd-9511-1008ed537e02";
const PACKAGING_DOCUMENT_TYPES = "14a52808-be4c-47a7-9f79-72b974d395e7";
const PROJECT_DOCUMENT_TYPES = "3dc90437-6aae-4c9c-9116-8694e7c714c2";

export const getCorrectDocument = async ({
  refListId,
  refListItemId,
  projectId,
  task,
}) => {
  // Define a common function to get the document from the API response
  const getDocument = (response, key) => {
    const document = response.data[key][0];
    return document ? { ...document, documentId: document.id } : [];
  };

  // Use an object to map the refListId to the API method and the response key
  const apiMap = {
    [PRODUCT_DOCUMENT_TYPES]: {
      method: projectApi.getListOfProductDocuments,
      key: "productDocuments",
    },
    [PACKAGING_DOCUMENT_TYPES]: {
      method: projectApi.getPackagingDocumentsFilter,
      key: "packagingDocuments",
    },
    [PROJECT_DOCUMENT_TYPES]: {
      method: projectApi.getListOfProjectDocuments,
      key: "projectDocuments",
    },
  };

  // Get the API method and the key from the map
  const { method, key } = apiMap[refListId] || {};

  // If the method is not found, return null
  if (!method) return null;

  // Call the API method with the parameters and get the document
  const response = await method({
    projectId,
    manufacturedItemId: task.manufacturedItemId,
    packagingComponentId: task.packagingComponentId,
    typeId: refListItemId,
    take: 1,
    skip: 0,
  });

  return getDocument(response, key);
};

export const getLastFileDetails = async ({
  refListId,
  refListItemId,
  projectId,
  task,
  languageCode,
  companyId,
}) => {
  try {
    if (task) {
      const specificDocument = await getCorrectDocument({
        refListId,
        refListItemId,
        projectId,
        task,
      });

      if (!isEmpty(specificDocument) && !isEmpty(specificDocument.files)) {
        const { data: fileDetail } = await documentApi.getFileDetailById(
          specificDocument.files[0].fileId
        );

        const lastFile = sortLatestByLockedStatus(
          fileDetail.fileHistory,
          "uploadedAt"
        );

        const { data: userData } =
          lastFile.length > 0 &&
          (await cumdApi.getPublicUsers({
            userIds: [lastFile[0].uploadedBy],
          }));

        const discussionIDObject = lastFile[0]?.links.find(
          link => link.objectTypeName === "Discussion"
        );

        // Get the comments changes by discussion id
        const {
          data: { commentsCounts },
        } =
          discussionIDObject &&
          (await discussionApi.getRootCommentsCount({
            discussionIds: [discussionIDObject.objectId],
          }));

        // TODO - Should be called once.
        // Get the document type by id
        const { data: documentType } =
          await referenceListApi.fetchReferenceListItems({
            id: refListId,
            languageCode,
            companyId,
          });

        return {
          ...lastFile[0],
          discussionId: discussionIDObject.objectId,
          fileId: lastFile[0].storageFileName.split("/")[1],
          attachedByUser: userData[0],
          commentsCounts: commentsCounts[0].commentsCount,
          versions: addDiscussionIdToLockedFiles(fileDetail.fileHistory),
          documentType: documentType.find(
            doc => doc.id === specificDocument.typeId
          )?.text,
          itemCode: documentType.find(doc => doc.id === specificDocument.typeId)
            ?.itemCode,
          documentPermissions: specificDocument.documentPermissions,
          status: specificDocument.status,
        };
      }
    }
  } catch (error) {
    // TODO should be handled.
    console.log("error" + error);
  }
};
