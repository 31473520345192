import { Column, ColumnStore } from "bryntum-gantt";
import styles from "routes/Tasks/TasksList/components/TableTasks.module.less";
import moment from "moment";
import { Tag } from "@trace-one/design-system";
import { renderToString } from "react-dom/server";
import { checkHardDateConstraintError, wait } from "./Utils";
import EstimatedEndDateCell, { renderIcon } from "./EstimatedEndDateCell";
import classNames from "classnames";
import { statusNames } from "constants/projects";

export default class EstimatedEndDateColumn extends Column {
  static get type() {
    return "dueDateUtc";
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      text: "Duration",
      htmlEncode: false,
    };
  }

  static getTimeFormat(wasRun) {
    return wasRun ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY";
  }

  static getColor(endDate, initialDueDateUtc, status, isDisabled) {
    const formattedInitialDueDate =
      moment(initialDueDateUtc).format("YYYY-MM-DD HH:mm");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD HH:mm");

    if (isDisabled) return "grey";

    if (
      !formattedInitialDueDate ||
      formattedEndDate <= formattedInitialDueDate
    ) {
      return "green";
    }

    if (formattedEndDate > formattedInitialDueDate) return "orange";

    if (
      ["InProgress", "Future"].includes(status) &&
      moment().isAfter(formattedInitialDueDate)
    ) {
      return "orange";
    }

    return "green";
  }

  static statusCheck(status) {
    return ["Approved", "Completed", "Rejected"].includes(status);
  }

  renderer({
    record: { originalData, endDate },
    column: {
      data: { wasRun, projectStatus },
    },
    rowElement,
    grid,
    record,
  }) {
    const {
      initialDueDateUtc,
      stepStatus,
      taskStatus,
      id,
      endDateUtc,
      hardDateConstraint,
      canBeLocked,
    } = originalData;

    const status = taskStatus || stepStatus || "Undefined";
    const isDisabled = !!hardDateConstraint?.EndDate;

    if (
      EstimatedEndDateColumn.statusCheck(status) ||
      (id === "projectRow" && endDateUtc)
    ) {
      return "-";
    }

    grid.on("beforeCellEditStart", event => {
      const {
        editorContext: { record },
      } = event;
      if (
        statusNames.InProgress === projectStatus &&
        record?.originalData?.hardDateConstraint?.EndDate
      ) {
        return !record?.originalData?.hardDateConstraint?.EndDate;
      }
    });

    rowElement.style.backgroundColor = isDisabled ? "#F5E8FF" : "white";

    if (canBeLocked && isDisabled && projectStatus === statusNames.InProgress) {
      const error = checkHardDateConstraintError(
        record?.stores[0]?.allRecords,
        record?.project
      );
      if (error) {
        rowElement.style.backgroundColor = "#FFDFDC";
      }
    }

    return {
      html: renderToString(
        <div className={styles.dueDateUtc}>
          <div
            className={classNames(styles.tagContainer, {
              [styles.tagContainerDisabled]: canBeLocked,
            })}
          >
            <Tag
              className={styles.setTagEstimatedEndDate}
              color={EstimatedEndDateColumn.getColor(
                endDate,
                initialDueDateUtc,
                status,
                canBeLocked
              )}
              label={moment(endDate).format(
                EstimatedEndDateColumn.getTimeFormat(wasRun)
              )}
              size="medium"
              mode="light"
            />
            {renderIcon(canBeLocked)}
          </div>

          {canBeLocked && (
            <input
              type="checkbox"
              id={`checkbox-${record.id}`}
              checked={record?.originalData?.hardDateConstraint?.EndDate}
              disabled
              className={styles.estimatedEndDateCheckbox}
            />
          )}
        </div>
      ),
    };
  }
}
export class EstimatedEndDateColumnCreation extends EstimatedEndDateColumn {
  static get type() {
    return "dueDateUtcCreation";
  }

  static getColor(endDate, initialDueDateUtc, status) {
    const initialDueDate = moment(initialDueDateUtc).format("YYYY-MM-DD HH:MM");
    const endDateFormatted = moment(endDate).format("YYYY-MM-DD HH:MM");
    let colorValue = "green";

    if (endDateFormatted > initialDueDate) {
      colorValue = "orange";
    }

    if (
      (status === "InProgress" || status === "Future") &&
      moment().format("YYYY-MM-DD HH:MM") > initialDueDate
    ) {
      colorValue = "orange";
    }

    return colorValue;
  }

  static statusCheck(status) {
    return ["Approved", "Completed", "Rejected"].includes(status);
  }

  renderer({ record, rowElement, grid, column }) {
    const {
      originalData: {
        initialDueDateUtc,
        stepStatus,
        taskStatus,
        id,
        endDateUtc,
        canBeLocked,
        hardDateConstraint,
        estimatedEndDateCheckBox,
      },
      endDate,
    } = record;

    const {
      data: {
        EstimatedEndDatecheckBoxChange,
        isEstimatedEndDateCellDisabled,
        status,
      },
    } = column;

    const {
      store: { records },
    } = grid;

    const disableOtherCells = () => {
      const anyChecked = records.some(
        rec =>
          rec.originalData.estimatedEndDateCheckBox ||
          rec.originalData.hardDateConstraint?.EndDate
      );

      records.forEach(rec => rec.set("disabled", anyChecked));
    };

    grid.on("beforeCellEditStart", event => {
      const { editorContext } = event;
      const { record, column } = editorContext;
      const isChecked = record.originalData?.estimatedEndDateCheckBox;

      if (
        statusNames.Scheduled === status &&
        record?.originalData?.hardDateConstraint?.EndDate
      ) {
        return !record?.originalData?.hardDateConstraint?.EndDate;
      }

      if (column?.field === "fullDuration") {
        return !isChecked;
      }

      if (column?.field === "endDate") {
        const canBeLocked = record.originalData?.canBeLocked;
        const columnChecked = isChecked || !!hardDateConstraint?.EndDate;
        return !columnChecked && canBeLocked;
      }
      return true;
    });

    const isEstimatedEndDateChecked =
      !!estimatedEndDateCheckBox || !!hardDateConstraint?.EndDate;
    const individualTaskStatus = taskStatus || stepStatus || "Undefined";

    const isCalendarIconVisible =
      isEstimatedEndDateChecked || status === statusNames.Scheduled;

    if (
      EstimatedEndDateColumn.statusCheck(individualTaskStatus) ||
      (id === "projectRow" && endDateUtc)
    ) {
      return "-";
    }

    const onCheckboxChange = e => {
      const isChecked = e.target.checked;

      record.originalData.estimatedEndDateCheckBox = isChecked;
      record.originalData.hardDateConstraint.EndDate = isChecked;
      record.originalData.locked = isChecked;
      record.set("dueDateUtcCreation", isChecked);

      rowElement.style.backgroundColor = isChecked ? "#F5E8FF" : "white";
      EstimatedEndDatecheckBoxChange({ record, records, target: e.target });

      disableOtherCells();
    };

    if (canBeLocked) {
      // Without this delay (wait function) - this change events will not be atatched or working in private mode (tested in edge)
      wait(0).then(() => {
        const checkboxElement = document.getElementById(
          `checkbox-${record.id}`
        );
        if (checkboxElement) {
          checkboxElement.removeEventListener("change", onCheckboxChange);
          checkboxElement.addEventListener("change", onCheckboxChange);

          checkboxElement.checked = isEstimatedEndDateChecked;
          checkboxElement.disabled = records.some(
            rec =>
              (rec.originalData.estimatedEndDateCheckBox ||
                rec.originalData.hardDateConstraint?.EndDate) &&
              rec.id !== record.id
          );
        }
      });
    }

    rowElement.style.backgroundColor = isEstimatedEndDateChecked
      ? "#F5E8FF"
      : "white";

    const isDisabled = !!hardDateConstraint?.EndDate;
    if (canBeLocked && isDisabled && status === statusNames.Scheduled) {
      const error = checkHardDateConstraintError(
        record?.stores[0]?.allRecords,
        record?.project
      );
      if (error) {
        rowElement.style.backgroundColor = "#FFDFDC";
      }
    }
    return {
      html: renderToString(
        <EstimatedEndDateCell
          endDate={endDate}
          isEstimatedEndDateChecked={isEstimatedEndDateChecked}
          canBeLocked={canBeLocked}
          record={record}
          records={records}
          taskStatus={individualTaskStatus}
          initialDueDateUtc={initialDueDateUtc}
          checkboxDisabled={isEstimatedEndDateCellDisabled}
          EstimatedEndDateColumn={EstimatedEndDateColumn}
          isCalendarIconVisible={isCalendarIconVisible}
        />
      ),
    };
  }
}

// Register both column types with the ColumnStore
ColumnStore.registerColumnType(EstimatedEndDateColumn);
ColumnStore.registerColumnType(EstimatedEndDateColumnCreation);
