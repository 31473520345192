import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectOwningCompanyId } from "common/selectors/users.selectors";
import { PackagingSystem } from "@trace-one/business-components";
import { useLanguagePreference, Spinner } from "@trace-one/react-components";
import styles from "./packagingSystem.module.less";
import useGetPackagingSystemDataForm from "./useGetPackagingSystemDataForm";

const EditPackagingSystem = () => {
  const languageCode = useLanguagePreference();
  const { id, taskId, manufItemId, packagingSystemId } = useParams<any>();
  const ownerCompanyId = useSelector(selectOwningCompanyId);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { refreshProject } = useGetPackagingSystemDataForm({
    projectId: id,
    taskId: taskId,
    ownerCompanyId: ownerCompanyId,
    manufacturedItemId: manufItemId,
    languageCode,
    setLoading,
  });

  function goBackOnTaskModal() {
    history.goBack();
  }

  return (
    <div className={styles.packagingSystem}>
      {loading ? (
        <div className={styles.spinner}>
          <Spinner size="large" />
        </div>
      ) : (
        <PackagingSystem
          canCreatePackaging
          languageCode={languageCode}
          packagingSystemId={packagingSystemId}
          onEditAndAssociate={async () => {
            await refreshProject();
          }}
          ownerCompanyId={ownerCompanyId}
          closePackagingSystemCreation={goBackOnTaskModal}
        />
      )}
    </div>
  );
};
export default EditPackagingSystem;
