/* istanbul ignore file */
import { DurationColumn, ColumnStore } from "bryntum-gantt";

export default class EstimatedDurationColumn extends DurationColumn {
  static get type() {
    return "estimatedDuration";
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      text: "Duration",
      htmlEncode: false,
      editor: { type: "durationfield" },
    };
  }

  static statusCheck(status: string) {
    return ["Approved", "Completed", "Rejected", "Cancelled"].includes(status);
  }

  renderer(data) {
    const {
      record: { isProjectMilestone, duration, originalData },
    } = data;

    if (isProjectMilestone || Number.isNaN(Number.parseFloat(duration))) {
      return "";
    }

    if (
      originalData?.id === "projectRow" ||
      originalData?.isStep ||
      originalData?.isSubProcessForManufacturedItems ||
      originalData?.isSubProcessForPackagingComponents ||
      originalData?.isSubProcessForTradeItems
    ) {
      return "";
    }

    if (EstimatedDurationColumn.statusCheck(originalData?.taskStatus)) {
      return originalData?.estimatedDuration;
    }

    return originalData?.estimatedDuration < 1
      ? "<1"
      : Math.round(originalData?.estimatedDuration);
  }
}

ColumnStore.registerColumnType(EstimatedDurationColumn);
