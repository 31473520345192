// TODO Test this file!
/* istanbul ignore file */
import { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { BryntumProject } from "components/Bryntum/BryntumProject";
import { BryntumToolBar } from "components/Bryntum/BryntumToolBar";
import { useLanguagePreference, Spinner } from "@trace-one/react-components";
import { createGanttConfig } from "services/messages/bryntum";
import { bryntumColumns } from "constants/bryntumSettings";
import { Alert } from "@trace-one/design-system";
import { registerData } from "redux/actions/ProjectCreation";
import {
  selectProjectTypeId,
  selectStartDate,
  selectIsBryntumLoading,
  selectBryntumModalData,
  selectUserResponsibilities,
  selectTeam,
  selectProjectId,
  selectIsSaved,
  selectTasks,
  selectProjectCreationCurrentData,
} from "common/selectors/projects.selectors";
import { fetchBryntumData } from "redux/actions/Bryntum";
import { selectValidatedProducts } from "common/selectors/filteredProducts.selectors";
import { statusNames } from "constants/projects";
import styles from "./BryntumTableOrGantt.module.less";
import {
  setEndDate,
  setStartDate,
  getAPIReadyInfoFromTasks,
  zoomToFit,
  getUpdatedStartDate,
  getDaysInBetween,
  checkHardDateConstraint,
  GanttPayload,
  processTaskLocking,
  isStartDateLesserThenToday,
} from "components/Bryntum/Utils";
import { hardDateComponent } from "routes/Projects/defineMessages";
import { selectBryntumColumnWidths } from "common/selectors/ganttView.selector";
import BryntumBannerDatePicker from "./BryntumBannerDatePicker";
import { isValidDate } from "utils/DateUtils";

const generateGanttPayload = (
  ganttRef,
  status,
  isProjectOwner: boolean
): GanttPayload => {
  if (!ganttRef?.current?.project?.startDate) {
    return null;
  }

  return {
    startDateUtc:
      ganttRef?.current?.taskStore?.allRecords[0]?.startDate?.toISOString(),
    endDateUtc: ganttRef.current.project.endDate?.toISOString(),
    tasks: getAPIReadyInfoFromTasks(ganttRef.current.tasks, [], {
      projectStatus: status,
      isProjectOwner,
    }),
  };
};

const BryntumTableOrGantt = ({ shouldrefresh, setRefresh }) => {
  const isSaved = useSelector(selectIsSaved);
  const userResponsibilities = useSelector(selectUserResponsibilities);
  const bryntumModelData = useSelector(selectBryntumModalData);
  const isLoading = useSelector(selectIsBryntumLoading);
  const teams = useSelector(selectTeam);
  const validatedProducts = useSelector(selectValidatedProducts);
  const startDate = useSelector(selectStartDate);
  const tasks = useSelector(selectTasks);
  const [config, setConfig] = useState<any>();
  const languageCode = useLanguagePreference();
  const projectTypeId = useSelector(selectProjectTypeId);
  const projectId = useSelector(selectProjectId);
  const dispatch = useDispatch();
  const intl = useIntl();
  const ganttRef = useRef<any>();
  const [datePickerDisabled, setDatePickerDisabled] = useState(false);
  const bryntumColumnWidths = useSelector(selectBryntumColumnWidths);
  const projectCurrentData = useSelector(selectProjectCreationCurrentData);

  useEffect(() => {
    if (isSaved || shouldrefresh) {
      dispatch(
        fetchBryntumData({
          projectId,
          projectTypeId,
          languageCode,
        })
      );
      setRefresh(false);
    }
  }, [isSaved]);

  const getTeamResponsibilities = () => {
    return userResponsibilities.map(responsibility => {
      const rteam = teams.responsibilities.find(
        rteam => rteam.id === responsibility.id
      );
      const valRet = {
        ...responsibility,
        responsibilityId: responsibility.id,
        responsibilityName: responsibility.text,
      };
      if (rteam) {
        const userId = rteam.userIdSelected;
        const userName = rteam.users.find(user => user.value === userId)?.text;
        return {
          ...valRet,
          userId,
          userName,
        };
      }
      return valRet;
    });
  };

  const setTaskEndDate = async ({ oldValue, value, userAction }) => {
    if (!userAction || !ganttRef.current) {
      return;
    }

    const startDate = ganttRef.current?.project?.startDate;
    const businessDaysInbetween = getDaysInBetween(oldValue, value);
    const newStartDate = getUpdatedStartDate(startDate, businessDaysInbetween);
    onStartDateChange(newStartDate);
  };

  const EstimatedEndDatecheckBoxChange = async ({
    record,
    records,
    target,
  }) => {
    processTaskLocking(records, record.originalData.id, target, ganttRef);

    const anyCheckboxInSelectedState = records.some(
      rec =>
        rec.originalData.estimatedEndDateCheckBox ||
        rec.originalData.hardDateConstraint?.EndDate
    );

    setDatePickerDisabled(anyCheckboxInSelectedState);
  };

  useEffect(() => {
    const project = ganttRef.current;
    const columns = bryntumColumns({
      intl,
      EstimatedEndDatecheckBoxChange,
      setTaskEndDate,
      project,
      bryntumColumnWidths,
    });
    if (!isLoading) {
      const config = createGanttConfig({
        bryntumModelData,
        columns: columns.projectWizardColumns,
        validatedProducts,
        teams: getTeamResponsibilities(),
        allResponsibilities: userResponsibilities,
        intl,
        canEdit: true,
        tasks,
      });

      if (bryntumModelData?.tasks?.rows) {
        let isHardDateSet = checkHardDateConstraint(
          bryntumModelData?.tasks?.rows
        );
        setDatePickerDisabled(isHardDateSet);
      }
      setConfig(config);
    }
  }, [
    isLoading,
    validatedProducts,
    teams,
    languageCode,
    intl,
    bryntumModelData,
    userResponsibilities,
    projectCurrentData?.tasks?.length,
  ]);

  const updateGanttPayloadInProjectData = data => {
    dispatch(registerData(data));
  };

  function onGanttReady() {
    setStartDate({
      startDate: isValidDate(startDate) ? new Date(startDate) : new Date(),
      ganttRef,
      onChange: updateGanttPayloadInProjectData,
    });

    ganttRef.current.project.on({
      dataReady() {
        onGanttChange();
      },
    });
  }

  const startDateChanged = useMemo(() => {
    return projectCurrentData.startDate?.split?.("T")?.[0];
  }, [projectCurrentData.startDate]);

  useEffect(() => {
    if (startDate) {
      const projectStartDate = ganttRef?.current?.project?.startDate
        ? new Date(ganttRef.current.project.startDate).toISOString()
        : null;
      const localStartDate = projectCurrentData?.startDateUtc
        ? new Date(projectCurrentData.startDateUtc).toISOString()
        : null;
      if (
        projectStartDate &&
        localStartDate &&
        projectStartDate !== localStartDate
      ) {
        ganttRef?.current?.project?.setStartDate(
          projectCurrentData.startDateUtc
        );
      }
    }
  }, [startDateChanged]);

  useEffect(() => {
    const taskStartDate =
      ganttRef?.current?.taskStore?.allRecords[0]?.startDate;
    const projectStartDate = ganttRef?.current?.project?.startDate;

    if (taskStartDate && projectStartDate) {
      const taskDateOnly = new Date(taskStartDate).toISOString().split("T")[0];
      const projectDateOnly = new Date(projectStartDate)
        .toISOString()
        .split("T")[0];

      if (taskDateOnly !== projectDateOnly) {
        if (ganttRef.current.project.startDate !== taskStartDate) {
          ganttRef.current.project.startDate = new Date(taskStartDate);
        }
      }
    }
  }, [ganttRef?.current?.taskStore?.allRecords[0]?.startDate]);

  const onGanttChange = () => {
    const ganttPayload = generateGanttPayload(
      ganttRef,
      statusNames.Draft,
      true
    );

    if (ganttPayload && projectCurrentData) {
      updateGanttPayloadInProjectData({
        endDate: ganttPayload.endDateUtc,
        startDate: ganttPayload.startDateUtc,
        tasks: ganttPayload.tasks,
      });
    }
    zoomToFit({ ganttRef });
  };

  function onStartDateChange(startDate: string | Date) {
    setStartDate({
      ganttRef,
      startDate: new Date(startDate),
      onChange: updateGanttPayloadInProjectData,
    });
  }

  function onEndDateChange(endDate: string) {
    setEndDate({
      ganttRef,
      endDate: new Date(endDate),
    });
  }

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.bryntumContainer}>
      {bryntumModelData?.hasLockableTask && (
        <div className={styles.hardDateTitle}>
          {intl.formatMessage(hardDateComponent.fixableTask)}
        </div>
      )}
      {isStartDateLesserThenToday(startDate) && (
        <Alert
          message={intl.formatMessage(hardDateComponent.projectLaunchedInPast)}
          description={intl.formatMessage(hardDateComponent.startDateInThePast)}
          type={"warning"}
          showIcon={true}
          closable={true}
        />
      )}
      <BryntumBannerDatePicker
        onStartDateChange={onStartDateChange}
        datePickerDisabled={datePickerDisabled}
        onEndDateChange={onEndDateChange}
        duration={ganttRef?.current?.project?.duration}
      ></BryntumBannerDatePicker>
      <BryntumProject
        config={config}
        ganttRef={ganttRef}
        onGanttReady={onGanttReady}
        isGanttViewFiltered
      />
      {ganttRef.current && (
        <BryntumToolBar
          config={config}
          ganttRef={ganttRef}
          displayCriticalPathSwitch={true}
        />
      )}
    </div>
  );
};

export default BryntumTableOrGantt;
