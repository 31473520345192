import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserLanguagePreference,
  selectUserIsLoading,
} from "common/selectors/users.selectors";
import { applicationLanguages } from "services/messages/utils";
import {
  LanguagePreferenceProvider,
  AxiosInstancesWrapper,
  LoadingPage,
} from "@trace-one/react-components";
import {
  referenceListApi,
  packagingApi,
  cumdApi,
  collaborationApi,
  productApi,
  notificationsApi,
  documentApi,
} from "apis";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { fetchUser, setUserPermissions } from "redux/actions/UserActions";
import { setOidcAccessToken } from "redux/actions/OidcActions";
import { getPermissions } from "services/messages/auth";
import {
  AxiosInstancesWrapper as BusinessAxiosInstancesWrapper,
  LanguagePreferenceProvider as BusinessLanguagePreferenceProvider,
} from "@trace-one/business-components";

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  const userLanguagePreference = useSelector(selectUserLanguagePreference);
  const isUserLoading = useSelector(selectUserIsLoading);
  const dispatch = useDispatch();

  const {
    oidcUser: {
      access_token,
      profile: {
        global_user_id,
        company_activity,
        permissions,
        global_user_is_admin,
        is_company_traceone,
        isMasquerade,
      },
    },
    events,
  } = useReactOidc();

  useEffect(() => {
    const saveOidcAccessToken = ({
      access_token,
    }: {
      access_token: string;
    }) => {
      dispatch(setOidcAccessToken({ access_token }));
    };

    //to keep the data type similar between function call and definition, we are destructuring the input after receiving
    const saveUserPermissions = profile => {
      const { permissions, global_user_is_admin } = profile;
      if (permissions !== undefined && global_user_is_admin !== undefined) {
        const userPermissions = getPermissions({
          permissions,
          global_user_is_admin,
          isMasquerade,
        });
        dispatch(setUserPermissions(userPermissions));
      }
    };

    saveOidcAccessToken({ access_token });
    saveUserPermissions({ permissions, global_user_is_admin });

    events.addUserLoaded(saveOidcAccessToken);
    events.addUserLoaded(user => saveUserPermissions(user.profile));
    events.addUserLoaded(user => saveUserPermissions(user.profile));

    return () => {
      events.removeUserLoaded(saveOidcAccessToken);
      events.removeUserLoaded(saveUserPermissions);
    };
  }, []);

  useEffect(() => {
    dispatch(
      fetchUser({
        userId: global_user_id,
        companyTraceOne: is_company_traceone,
      })
    );
  }, [global_user_id, company_activity, dispatch]);

  if (isUserLoading || !userLanguagePreference)
    return (
      <LoadingPage
        title="LOADING..."
        subtitle="Please wait, your page will be displayed"
      />
    );

  return (
    <AxiosInstancesWrapper
      axiosInstanceRLMD={referenceListApi.instance}
      axiosInstancePKG={packagingApi.instance}
      axiosInstanceCUMD={cumdApi.instance}
      axiosInstancePMD={productApi.instance}
      axiosInstanceCLB={collaborationApi.minimalInstance}
      axiosInstanceDOCUMENT={documentApi.instance}
    >
      <BusinessAxiosInstancesWrapper
        axiosInstanceCUMD={cumdApi.instance}
        axiosInstanceRLMD={referenceListApi.instance}
        axiosInstancePKG={packagingApi.instance}
        axiosInstancePMD={productApi.instance}
        axiosInstanceCLB={collaborationApi.minimalInstance}
        axiosInstanceNOTIF={notificationsApi.instance}
        axiosInstanceDOCUMENT={documentApi.instance}
      >
        <LanguagePreferenceProvider
          translations={applicationLanguages}
          languageCode={userLanguagePreference}
        >
          <BusinessLanguagePreferenceProvider
            languageCode={userLanguagePreference}
          >
            {children}
          </BusinessLanguagePreferenceProvider>
        </LanguagePreferenceProvider>
      </BusinessAxiosInstancesWrapper>
    </AxiosInstancesWrapper>
  );
};

export default Wrapper;
