import { ProjectModel, TaskModel, EffectResolutionResult } from "bryntum-gantt";
import { defineMessages } from "react-intl";
import { ganttSettings } from "constants/bryntumSettings";
import { flatten } from "lodash";

let lockedTaskId = null; // End date locked task id

const messages = defineMessages({
  noProducts: {
    id: "components.bryntumContent.noProductsYet",
    defaultMessage: "No product selected yet",
  },
  noPackagingSelected: {
    id: "components.bryntumContent.noPackagingSelected",
    defaultMessage: "No packaging components selected",
  },
});

const createNewBryntumModalDate = bryntumModelData => {
  return {
    resourcesData:
      bryntumModelData.resources && bryntumModelData.resources.rows,
    calendarsData:
      bryntumModelData.calendars && bryntumModelData.calendars.rows,
    dependenciesData:
      bryntumModelData.dependencies && bryntumModelData.dependencies.rows
        ? bryntumModelData.dependencies.rows
        : [],
    assignmentsData:
      bryntumModelData.assignments && bryntumModelData.assignments.rows,
    tasksData: bryntumModelData.tasks.rows,
  };
};

class ProjectTaskModel extends TaskModel {
  static get fields() {
    return [{ name: "actualEndDate", type: "date" }];
  }
}

const getAction = props => elem => {
  if (props.taskModal) {
    if (
      !elem.record.originalData.name.includes("Milestone") &&
      elem.column.data.type === "name"
    ) {
      if (elem.record.originalData.userTaskId) {
        props.taskModal(elem.record.originalData.userTaskId);
      }
    }
  }
  return null;
};

export const createGanttConfig = props => {
  const config = {
    displaySchedulingIssueResolutionPopup: false,
    project: new ProjectModel({
      taskModelClass: ProjectTaskModel,
      calendar: "general",
      ...createData(props),
      autoLoad: true,
      // autoSetConstraints: true,
      autoSchedule: false,
      listeners: {
        schedulingConflict(prop) {
          const { continueWithResolutionResult } = prop;
          continueWithResolutionResult(EffectResolutionResult.Continue);
        },
      },
    }),
    listeners: {
      cellClick: e => {
        getAction(props)(e);
      },
    },
    columns: props.columns,
    ...ganttSettings(props.canEdit, props.intl),
    userId: props.userId,
  };

  if (props.intl) {
    config.intl = props.intl;
  }

  return config;
};

export const isValidBryntumModel = (bryntumModelData = []) => {
  if (
    bryntumModelData.tasks &&
    bryntumModelData.tasks.rows &&
    bryntumModelData.tasks.rows[0] &&
    bryntumModelData.tasks.rows[0].children
  ) {
    return true;
  }
  return false;
};

const getPackagingComponentName = (
  packagingComponentId,
  packagingComponents,
  intl
) => {
  const { formatMessage } = intl;
  const name = packagingComponents.find(
    ({ componentId }) => componentId === packagingComponentId
  )?.name;
  return name ?? formatMessage(messages.noPackagingSelected);
};

export const findUserResponsibility = (id, allResponsibilities = []) =>
  allResponsibilities.find(ur => ur.id === id)?.text;

const updateTaskDuration = (task, tasks) => {
  const newDuration = tasks.find(
    t => t.taskId === task.userTaskId
  )?.durationInDays;
  return newDuration ?? task.duration;
};

const handleSubProcess = (
  subprocess,
  validatedProducts = [],
  packagingComponents,
  teams,
  allResponsibilities,
  ganttRessourceLabel,
  intl,
  tasks
) => {
  const { formatMessage } = intl;
  const getMapChildren = (children, productId) => {
    let childrenMapped = children.map(task => {
      if (task?.hardDateConstraint?.EndDate) {
        lockedTaskId = task.id; // Only end date locked task id being set
      }
      let taskMapped = {
        ...task,
        duration: updateTaskDuration(task, tasks),
      };

      if (task.userResponsibilityId) {
        taskMapped = {
          ...taskMapped,
          ...findUser(ganttRessourceLabel, task),
          userResponsibility: findUserResponsibility(
            task.userResponsibilityId,
            allResponsibilities
          ),
        };
      }

      if (!!task.isSubProcessForPackagingComponents)
        taskMapped = {
          ...taskMapped,
          name: getPackagingComponentName(
            task.packagingComponentId,
            packagingComponents,
            intl
          ),
        };

      if (task.children) {
        taskMapped = {
          ...taskMapped,
          children: getMapChildren(task.children, productId),
        };
      }

      return taskMapped;
    });

    return childrenMapped;
  };
  const productId = subprocess.isSubProcessForManufacturedItems
    ? subprocess.manufacturedItemId
    : subprocess.isSubProcessForTradeItems
    ? subprocess.tradeItemId
    : null;

  const getProductInfo = productId => {
    const product = validatedProducts.find(({ id }) => id === productId);
    if (product) {
      const { itemName, brandName: brand = null } = product;
      const brandName = brand ? `- ${brand}` : "";
      return {
        name: `${itemName} ${brandName}`,
        toolTip: {
          netContentValue: product.netContentValue,
          netContentUnitText: product.netContentUnitText,
          gtin: product.gtin,
        },
      };
    }
    return { name: formatMessage(messages.noProducts) };
  };

  const row = {
    ...subprocess,
    ...getProductInfo(productId),

    children:
      subprocess.children && getMapChildren(subprocess.children, productId),
  };

  return row;
};

const findUser = (ganttRessourceLabel = [], { assignedUserId }) => {
  let user;
  if (assignedUserId != null) {
    user = ganttRessourceLabel?.find(
      ressource => ressource.userId === assignedUserId
    );
  }

  return {
    userName: user?.userName,
    userId: user?.userId,
  };
};

const handleUserTasks = (
  task,
  teams,
  allResponsibilities,
  tasks,
  ganttRessourceLabel
) => {
  return {
    ...task,
    duration: updateTaskDuration(task, tasks),
    ...findUser(ganttRessourceLabel, task),
    userResponsibility: findUserResponsibility(
      task.userResponsibilityId,
      allResponsibilities
    ),
    children:
      task.children &&
      task.children.map(t => ({
        ...t,
        ...findUser(ganttRessourceLabel, t),
        userResponsibility: findUserResponsibility(
          t.userResponsibilityId,
          allResponsibilities
        ),
      })),
  };
};

const newChildren = (
  children = [],
  validatedProducts,
  teams,
  allResponsibilities,
  ganttRessourceLabel,
  intl,
  tasks,
  packagingComponents
) => {
  const newChildrenArray = children.map(task => {
    if (task?.hardDateConstraint?.EndDate) {
      lockedTaskId = task.id; // Only end date locked task id being set
    }
    if (task.taskProgressCode && task.taskProgressCode === "Behind") {
      task.cls = task.cls + " delayed";
    }
    if (task.isSubProcessForTradeItems) {
      const tradeItems = validatedProducts.filter(
        ({ isManufacturedItem }) => !isManufacturedItem
      );
      return handleSubProcess(
        task,
        tradeItems,
        packagingComponents,
        teams,
        allResponsibilities,
        ganttRessourceLabel,
        intl,
        tasks
      );
    }
    if (task.isSubProcessForManufacturedItems) {
      const manufItems = validatedProducts.filter(
        ({ isManufacturedItem }) => !!isManufacturedItem
      );
      return handleSubProcess(
        task,
        manufItems,
        packagingComponents,
        teams,
        allResponsibilities,
        ganttRessourceLabel,
        intl,
        tasks
      );
    }
    return handleUserTasks(
      task,
      teams,
      allResponsibilities,
      tasks,
      ganttRessourceLabel
    );
  });
  return flatten(newChildrenArray);
};

// we need to update this function when we work on the subgrids in the next sprint
export const createData = ({
  bryntumModelData,
  teams,
  allResponsibilities,
  ganttRessourceLabel,
  packagingComponents = [],
  validatedProducts,
  intl,
  tasks = [],
}) => {
  lockedTaskId = null;
  if (isValidBryntumModel(bryntumModelData)) {
    return {
      ...createNewBryntumModalDate(bryntumModelData),
      // startDate: bryntumModelData.project?.startDateUtc || new Date(),
      tasksData: bryntumModelData.tasks.rows.map(r => ({
        ...r,
        children: r.children.map(step => ({
          ...step,
          children:
            step.children &&
            newChildren(
              step.children,
              validatedProducts,
              teams,
              allResponsibilities,
              ganttRessourceLabel,
              intl,
              tasks,
              packagingComponents
            ),
        })),
      })),
      dependenciesData: flatten(bryntumModelData.dependencies.rows),
      lockedTaskId: lockedTaskId,
    };
  }
};
