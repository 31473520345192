import { useAssociatePackaging } from "hooks";
import { useHistory, useParams } from "react-router";

const useCreateAssociatePackaging = ({
  id,
  taskId,
  setIsSaving,
  packagingSystems,
  fetchProject,
  fetchTask,
  onRefreshProject,
  manufacturedItemId,
  formBlocks,
  packagingSystemToProductRelations,
  setLoading = e => {},
}) => {
  const history = useHistory();
  const { templateBlockId, dataAsJson } = formBlocks[0];

  const urlParams = useParams();

  const { associatePackaging, temporaryAssociatePackaging } =
    useAssociatePackaging({
      selectedProducts: [manufacturedItemId as string],
      taskId,
      projectData: {
        packagingSystems,
        id,
        packagingSystemToProductRelations,
        templateBlockId,
        dataAsJson,
      },
    });

  const handleOnAssociate = async packagingSystemIds => {
    try {
      await associatePackaging(packagingSystemIds);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnTemporaryAssociate = async packagingSystemIds => {
    try {
      await temporaryAssociatePackaging(packagingSystemIds);
      //TODO needs to be review
      fetchTask();
    } catch (error) {
      console.error(error);
    }
  };

  const refreshProject = async (params = { unmounted: true }) => {
    const { unmounted } = params;

    try {
      if (unmounted) {
        setLoading(true);
        setIsSaving(true);
        onRefreshProject(true);
        await Promise.all([fetchProject(), fetchTask()]).then(() => {
          // Prevent - closing the task details modal—temp solution. The pack system should be refactored.
          if (urlParams.hasOwnProperty("manufItemId")) {
            history.goBack();
          }
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  return {
    handleOnAssociate,
    handleOnTemporaryAssociate,
    refreshProject,
  };
};

export default useCreateAssociatePackaging;
