/* istanbul ignore file */
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import TraceOneWeekPicker from "components/TraceOneWeekPicker/TraceOneWeekPicker";
import { Tooltip } from "@trace-one/design-system";
import {
  selectEndDate,
  selectStartDate,
} from "common/selectors/projects.selectors";
import styles from "./BryntumTableOrGantt.module.less";
import { projectHeader } from "routes/Projects/defineMessages";
import { getBusinessDayAfterDuration } from "utils/DateUtils";

interface BryntumBannerDatePickerProps {
  onStartDateChange: (x: any) => void;
  onEndDateChange: (x: any) => void;
  datePickerDisabled: boolean;
  duration: number;
}

const BryntumBannerDatePicker = ({
  onStartDateChange,
  datePickerDisabled,
  onEndDateChange,
  duration,
}: BryntumBannerDatePickerProps) => {
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const intl = useIntl();

  const enableEndDateFromDate = useMemo(() => {
    if (duration) {
      return getBusinessDayAfterDuration(duration);
    }
  }, [duration]);

  // if (!isValidDate(startDate)) {
  //   return null;
  // }

  return (
    <Tooltip
      title={
        datePickerDisabled
          ? intl.formatMessage(projectHeader.tooltipStartandEndDate)
          : ""
      }
      placement="top"
      size="large"
    >
      <span className={styles.header}>
        <div className={styles.dateWrapper}>
          <div className={styles.date}>
            <span className={styles.label}>
              <FormattedMessage
                id="projects.creation.planning.components.bryntumTableOrGantt.startDate"
                defaultMessage="Start date"
              />
            </span>
            <TraceOneWeekPicker
              name="startDate"
              value={startDate}
              onChange={onStartDateChange}
              disabled={datePickerDisabled}
            />
          </div>
          <div className={styles.date}>
            <span className={styles.label}>
              <FormattedMessage
                id="projects.creation.planning.components.bryntumTableOrGantt.endDate"
                defaultMessage="End date"
              />
            </span>
            <TraceOneWeekPicker
              enableFrom={enableEndDateFromDate}
              name="endDate"
              value={endDate}
              onChange={onEndDateChange}
              disabled={datePickerDisabled}
            />
          </div>
        </div>
      </span>
    </Tooltip>
  );
};

export default memo(BryntumBannerDatePicker);
