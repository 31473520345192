import React from "react";
import { Tag } from "@trace-one/design-system";
import styles from "routes/Tasks/TasksList/components/TableTasks.module.less";
import moment from "moment";
import Icon from "components/Icon";

export const renderIcon = (isChecked: boolean): JSX.Element | null => {
  if (isChecked) {
    return (
      <div className={styles.calender}>
        <Icon name="Calendar" />
      </div>
    );
  }
  return <></>;
};

type EstimatedEndDateColumn = any;

interface Record {
  id: string;
  originalData: {
    estimatedEndDateCheckBox?: boolean;
    hardDateConstraint?: {
      EndDate?: boolean;
    };
  };
}

interface EstimatedEndDateCellProps {
  endDate: Date;
  isEstimatedEndDateChecked: boolean;
  canBeLocked: boolean;
  record: Record;
  records: Record[];
  taskStatus: string;
  checkboxDisabled?: boolean;
  initialDueDateUtc: Date;
  EstimatedEndDateColumn: EstimatedEndDateColumn;
  isCalendarIconVisible?: boolean;
}

const formatEndDate = (endDate: Date): string =>
  moment(endDate).format("DD/MM/YYYY");

const renderTag = (
  endDate: Date,
  EstimatedEndDateColumn: EstimatedEndDateColumn,
  initialDueDateUtc: Date,
  status: string
) => (
  <Tag
    className={styles.setTagEstimatedEndDate}
    color={EstimatedEndDateColumn.getColor(endDate, initialDueDateUtc, status)}
    label={formatEndDate(endDate)}
    size="medium"
    mode="light"
  />
);

const isCheckboxDisabled = (records: Record[], recordId: string): boolean =>
  records.some(
    item =>
      (item.originalData.estimatedEndDateCheckBox ||
        item.originalData.hardDateConstraint?.EndDate) &&
      item.id !== recordId
  );

const EstimatedEndDateCell = ({
  endDate,
  isEstimatedEndDateChecked,
  isCalendarIconVisible,
  canBeLocked,
  record,
  records,
  taskStatus,
  initialDueDateUtc,
  EstimatedEndDateColumn,
  checkboxDisabled,
}: EstimatedEndDateCellProps) => {
  const isEnable = isCheckboxDisabled(records, record.id);

  return (
    <div className={styles.estimatedEndDateCheckBox}>
      {canBeLocked ? (
        <div
          className={
            isEnable ||
            isEstimatedEndDateChecked ||
            (checkboxDisabled && canBeLocked)
              ? styles.tagDisable
              : styles.tagEnable
          }
        >
          <span>{formatEndDate(endDate)}</span>
          <span className={styles.icons}>
            {renderIcon(isCalendarIconVisible)}
          </span>
        </div>
      ) : (
        renderTag(
          endDate,
          EstimatedEndDateColumn,
          initialDueDateUtc,
          taskStatus
        )
      )}
      {canBeLocked && (
        <input
          type="checkbox"
          checked={isEstimatedEndDateChecked}
          id={`checkbox-${record.id}`}
          disabled={isEnable || checkboxDisabled}
          onChange={() => {}}
          className={styles.estimatedEndDatecheckbox}
        />
      )}
    </div>
  );
};

export default EstimatedEndDateCell;
