/* istanbul ignore file */
import { axiosInstance } from "./axiosInstance";

export default class PROJECT extends axiosInstance {
  getResponsibilitiesByProjectTypeId = ({ projectTypeId }) =>
    this.instance.get(`/api/projectTypes/${projectTypeId}/responsibilities`);

  getProjectById = ({ projectId, languageCode }) =>
    this.instance.get(`/api/projects/${projectId}`, {
      params: {
        languageCode,
      },
    });

  getProjectTargetTasks = ({ id, taskId }) => {
    return this.instance.get(
      `/api/v2/projects/${id}/tasks/${taskId}/copy/target-tasks`
    );
  };

  copyTargetTasks = ({
    id,
    taskId,
    targetTasksId,
    completeSourceTask,
    completeTargetTasks,
  }) => {
    return this.instance.post(`/api/v2/projects/${id}/tasks/${taskId}/copy`, {
      targetTasksId,
      completeSourceTask,
      completeTargetTasks,
    });
  };

  postDraft = ({
    ownerCompanyId,
    ownerUserId,
    name,
    projectTypeId,
    description,
    projectCode,
  }) =>
    this.instance.post("/api/projects/draft", {
      ownerCompanyId,
      ownerUserId,
      name,
      projectTypeId,
      description,
      projectCode,
    });

  patchProjectById = ({
    projectId,
    name,
    projectTypeId,
    description,
    projectCode,
  }) =>
    this.instance.patch(`/api/projects/${projectId}/projectDefinition`, {
      name,
      projectTypeId,
      description,
      projectCode,
    });

  patchProductsByTradeItemId = ({ projectId, tradeItemIds }) =>
    this.instance.patch(`/api/projects/${projectId}/tradeItems`, {
      tradeItemIds,
    });

  patchProductsByManufacturedItemId = ({ projectId, manufacturedItemIds }) =>
    this.instance.patch(`/api/projects/${projectId}/manufacturedItems`, {
      manufacturedItemIds,
    });

  patchDatesByProjectId = ({ projectId, startDateUtc, tasks }) =>
    this.instance.patch(`/api/projects/${projectId}/timetable`, {
      startDateUtc,
      tasks,
    });

  patchTeamByProjectId = ({ projectId, team, thirdPartyCompanies }) =>
    this.instance.patch(`/api/projects/${projectId}/team`, {
      team,
      thirdPartyCompanies,
    });

  patchProjectOnHoldStatus = (projectId, putOnHold) => {
    const params = putOnHold ? { putOnHold: true } : { resumeFromOnHold: true };
    return this.instance.patch(
      `/api/projects/${projectId}/switchOnHoldStatus`,
      { ...params }
    );
  };

  patchPackagingSystemToProject = (projectId, packagingSystemIds) => {
    return this.instance.patch(`/api/projects/${projectId}/packagingSystems`, {
      packagingSystemIds,
    });
  };

  patchPackagingSystemToProduct = (
    projectId,
    packagingSystemToProductRelations
  ) => {
    return this.instance.patch(
      `/api/projects/${projectId}/packagingSystemToProductRelations`,
      { packagingSystemToProductRelations }
    );
  };

  postActivateProject = ({ projectId }) =>
    this.instance.post(`/api/projects/${projectId}/activate`);

  verifyProjectTypeFile = ({ ownerCompanyId, bpmnFileBase64 }) =>
    this.instance.post("/api/projectTypeFiles/verify", {
      ownerCompanyId,
      bpmnFileBase64,
    });

  uploadProjectTypeFile = ({ ownerCompanyId, bpmnFileName, bpmnFileBase64 }) =>
    this.instance.post("/api/projectTypeFiles/upload", {
      ownerCompanyId,
      bpmnFileName,
      bpmnFileBase64,
    });

  getProjectTypes = ({
    ownerCompanyId,
    languageCode,
    active,
    inactive,
    draft,
  }) =>
    this.instance.get("/api/projectTypes", {
      params: { ownerCompanyId, languageCode, active, inactive, draft },
    });

  activateProjectType = ({ projectTypeId }) =>
    this.instance.post(`/api/projectTypes/${projectTypeId}/activate`);

  deactivateProjectType = ({ projectTypeId }) =>
    this.instance.post(`/api/projectTypes/${projectTypeId}/deactivate`);

  getProjectSummaryById = ({ projectId }) =>
    this.instance.get(`/api/projects/${projectId}/summary`);

  getProjectsByUserIdAndFilter = ({ ...filters }) =>
    this.instance.get("/api/projects", {
      params: {
        ...filters,
      },
    });

  getProjectsIds = ({ ownedByUser, userId, languageCode, ...filters }) =>
    this.instance.get("/api/projects/ids", {
      params: {
        ...filters,
        userId,
        languageCode,
        ownedByUser,
      },
    });

  getTasksIds = ({ userId, languageCode, ...filters }) =>
    this.instance.get("/api/userTasks/ids", {
      params: {
        ...filters,
        userId,
        languageCode,
      },
    });

  getTaskByTaskId = ({ taskId, languageCode }) =>
    this.instance.get(`/api/usertasks/${taskId}`, { params: { languageCode } });

  completeTaskById = ({ taskId }) =>
    this.instance.post(
      `/api/userTasks/${taskId}/complete`,
      {},
      {
        useSilentError: true,
      }
    );

  archiveProjectById = ({ projectId }) =>
    this.instance.post(
      `/api/projects/${projectId}/archive`,
      {},
      {
        useSilentError: true,
      }
    );

  deleteProjectById = ({ projectId }) =>
    this.instance.delete(`/api/projects/${projectId}`);

  approveTaskById = ({ taskId }) =>
    this.instance.post(
      `/api/userTasks/${taskId}/approve`,
      {},
      {
        useSilentError: true,
      }
    );

  rejectTaskById = ({ taskId, comment }) =>
    this.instance.post(
      `/api/userTasks/${taskId}/reject`,
      {
        comment,
      },
      {
        useSilentError: true,
      }
    );

  saveTaskFormById = ({ taskId, templateBlockId, dataAsJson }) =>
    this.instance.post(`/api/userTasks/${taskId}/formData`, {
      templateBlockId,
      dataAsJson,
    });

  saveTaskFormByIdWithPackageSystem = ({
    taskId,
    templateBlockId,
    dataAsJson,
    auxiliaryData,
  }) =>
    this.instance.post(`/api/userTasks/${taskId}/formData`, {
      templateBlockId,
      dataAsJson,
      auxiliaryData,
    });

  getUserTasksByUserIdAndFilter = ({ ...filters }) =>
    this.instance.get("/api/userTasks", {
      params: {
        ...filters,
      },
    });

  patchTeamByTeamId = ({
    teamId,
    name,
    productCategoryId,
    brandId,
    isShared,
    responsibilities,
  }) =>
    this.instance.patch(`/api/teams/${teamId}`, {
      name,
      productCategoryId,
      brandId,
      isShared,
      responsibilities,
    });

  deleteTeamByTeamId = ({ teamId }) =>
    this.instance.delete(`/api/teams/${teamId}`);

  createTeam = ({
    name,
    ownerUserId,
    ownerCompanyId,
    projectTypeId,
    productCategoryId,
    brandId,
    isShared,
    responsibilities,
  }) =>
    this.instance.post("/api/teams/create", {
      name,
      ownerUserId,
      ownerCompanyId,
      projectTypeId,
      productCategoryId,
      brandId,
      isShared,
      responsibilities,
    });

  getTeamsByFilter = ({ ...filters }) =>
    this.instance.get("/api/teams", {
      params: {
        ...filters,
      },
    });

  getTransactionExcelFile = ({ projectTypeId }) =>
    this.instance.get(
      `/api/projectTypes/${projectTypeId}/translationsExcelFile`
    );

  postTransactionExcelFile = ({
    projectTypeId,
    ownerCompanyId,
    bpmnFileBase64,
  }) =>
    this.instance.post(
      `/api/projectTypes/${projectTypeId}/importTranslationsExcelFile`,
      {
        ownerCompanyId,
        bpmnFileBase64,
      }
    );

  verifySubstituteUser = ({ originalUserId, projectIds }) =>
    this.instance.post("/api/projects/substituteUser/verify", {
      originalUserId,
      projectIds,
    });

  verifySubstituteUserTask = ({
    originalUserId,
    taskIds,
    languageCode,
    assignUserIfNoUserAssigned,
  }) =>
    this.instance.post("/api/userTasks/substituteUser/verify", {
      languageCode,
      originalUserId,
      taskIds,
      assignUserIfNoUserAssigned,
    });

  substituteUser = ({ originalUserId, projectIds, newUsers }) =>
    this.instance.post("/api/projects/substituteUser", {
      originalUserId,
      projectIds,
      newUsers,
    });

  substituteUserTask = ({
    originalUserId,
    taskIds,
    newUsers,
    assignUserIfNoUserAssigned,
  }) =>
    this.instance.post("/api/userTasks/substituteUser", {
      originalUserId,
      taskIds,
      newUsers,
      assignUserIfNoUserAssigned,
    });

  substituteUserProcessState = ({ processId }) =>
    this.instance.get(`/api/projects/substituteUser/processState/${processId}`);

  substituteUserTaskProcessState = ({ processId, languageCode }) =>
    this.instance.get(
      `/api/userTasks/substituteUser/processState/${processId}`,
      { params: { languageCode } }
    );

  getAssignedUsers = ({ taskIds }) =>
    this.instance.post("/api/userTasks/getAssignedUsers", { taskIds });

  getCompaniesAndUsersInTeam = ({ projectIds }) =>
    this.instance.post("/api/projects/getCompaniesAndUsersInTeam", {
      projectIds,
    });

  getComponentDesignByFilter = ({
    projectId,
    userId,
    packagingComponentId,
    manufacturedItemId,
  }) =>
    this.instance.post(`/api/packagingComponentDesigns/filter`, {
      projectId,
      userId,
      packagingComponentId,
      manufacturedItemId,
    });

  getPackagingDocumentsFilter = ({ take = 1000, ...rest }) =>
    this.instance.post(`/api/packagingDocuments/filter`, {
      take,
      ...rest,
    });

  addFileAccessRule = ({
    fileId,
    ManufacturedItemId,
    ProjectId,
    UploadByUserId,
  }) =>
    this.instance.post(`/api/projects/addfileaccessrule`, {
      FileId: fileId,
      ManufacturedItemId: ManufacturedItemId,
      ProjectId: ProjectId,
      UploadByUserId: UploadByUserId,
    });

  getProductDesignsByFilter = ({
    projectId,
    skip,
    take,
    productDesignIds,
    manufacturedItemId,
  }) =>
    this.instance.post(`/api/productDesigns/filter`, {
      projectId,
      skip,
      take,
      productDesignIds,
      manufacturedItemId,
    });

  getListOfProjectDocuments = ({ ...rest }) =>
    this.instance.post("/api/projectDocuments/filter", {
      ...rest,
    });

  attachProjectDocuments = ({ ...rest }) =>
    this.instance.post("/api/projectDocuments/attachFile", {
      ...rest,
    });

  // attach Project Documents for documents that are in a validated state
  reviseProjectDocuments = ({ ...rest }) =>
    this.instance.patch("/api/projectDocuments/revise", {
      ...rest,
    });

  reviseProductDocuments = ({ ...rest }) =>
    this.instance.patch("/api/productDocuments/revise", {
      ...rest,
    });

  reviseComponentDocuments = ({ ...rest }) =>
    this.instance.patch("/api/packagingDocuments/revise", {
      ...rest,
    });

  getListOfProductDocuments = ({ ...rest }) =>
    this.instance.post("/api/productDocuments/filter", {
      ...rest,
    });

  // Re-implemented
  reviseDocuments = (params, projectDocumentId, reviseType) => {
    const reviseDocumentTypes = {
      packaging: {
        entity: "packagingDocuments",
        body: {
          ...params,
          packagingDocumentId: projectDocumentId,
        },
      },
      product: {
        entity: "productDocuments",
        body: {
          ...params,
          productDocumentId: projectDocumentId,
        },
      },
      project: {
        entity: "projectDocuments",
        body: {
          ...params,
          projectDocumentId,
        },
      },
    };

    const APIdetails =
      reviseDocumentTypes[reviseType] || reviseDocumentTypes.product;

    const { entity, body } = APIdetails;

    return this.instance.patch(`/api/${entity}/revise`, {
      ...body,
    });
  };

  attachFileToProductDocument = ({ fileId, productDocumentId, ...rest }) =>
    this.instance.post(`/api/productDocuments/attachFile`, {
      fileId,
      productDocumentId,
      ...rest,
    });

  attachFileToPackageDocument = ({ fileId, packagingDocumentId, ...rest }) =>
    this.instance.post(`/api/packagingDocuments/attachFile`, {
      fileId,
      packagingDocumentId,
      ...rest,
    });

  // Re-implemented
  attachFile = (params, projectDocumentId, attachType) => {
    const attachDocumentTypes = {
      packaging: {
        entity: "packagingDocuments",
        body: {
          ...params,
          packagingDocumentId: projectDocumentId,
        },
      },
      product: {
        entity: "productDocuments",
        body: {
          ...params,
          productDocumentId: projectDocumentId,
        },
      },
      project: {
        entity: "projectDocuments",
        body: {
          ...params,
          projectDocumentId,
        },
      },
    };

    const APIdetails =
      attachDocumentTypes[attachType] || attachDocumentTypes.product;

    const { entity, body } = APIdetails;

    return this.instance.post(`/api/${entity}/attachFile`, {
      ...body,
    });
  };

  updatePackagingDocumentStatus = ({ packagingDocumentId, status }) =>
    this.instance.patch(
      `/api/packagingDocuments/${packagingDocumentId}/updateStatus`,
      {
        status,
      }
    );

  updateProjectDocumentStatus = ({ projectDocumentId, status }) =>
    this.instance.patch(
      `/api/projectDocuments/${projectDocumentId}/updateStatus`,
      {
        status,
      }
    );

  updateProductDocumentStatus = ({ productDocumentId, status }) =>
    this.instance.patch(
      `/api/productDocuments/${productDocumentId}/updateStatus`,
      {
        status,
      }
    );

  getSubprocessById = ({ projectId, ...filters }) =>
    this.instance.get(`/api/projects/${projectId}/subprocessProgresses`, {
      params: {
        ...filters,
      },
    });

  patchAdditionalProjectOwners = ({ projectId, additionalOwnerUserIds }) =>
    this.instance.patch(`/api/projects/${projectId}/additionalProjectOwners`, {
      additionalOwnerUserIds,
    });
}
