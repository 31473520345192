import React, { useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Switch } from "@trace-one/react-components";
import { createBryntumFilter } from "./Utils";
import styles from "./BryntumToolBar.module.less";
import { useHistory } from "react-router-dom";

export const BryntumToolBar = ({
  config,
  ganttRef,
  displayCriticalPathSwitch = false,
}) => {
  const intl = useIntl();
  const bryntumToolBarTrad = defineMessages({
    collapse: {
      id: "components.bryntum.bryntumToolBar.collapse",
      defaultMessage: "Collapse all",
    },
    expand: {
      id: "components.bryntum.bryntumToolBar.expand",
      defaultMessage: "Expand all",
    },
    myTasks: {
      id: "components.bryntum.bryntumToolBar.myTasks",
      defaultMessage: "display my tasks only",
    },
    allTasks: {
      id: "components.bryntum.bryntumToolBar.allTasks",
      defaultMessage: "display all the tasks",
    },
    criticalPathShow: {
      id: "components.bryntum.bryntumToolBar.criticalPathShow",
      defaultMessage: "Show critical path",
    },
    criticalPathHide: {
      id: "components.bryntum.bryntumToolBar.criticalPathHide",
      defaultMessage: "Hide critical path",
    },
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCriticalPathDisabled, setIsCriticalPathDisabled] = useState(false);
  const [displayMyTasks, setDisplayMyTasks] = useState(false);
  const creationPlanningPath = "/projects/creation/planning";
  const { location } = useHistory();

  const isDisabled = location.pathname === creationPlanningPath;

  const toggleCriticalPath = () => {
    setIsCriticalPathDisabled(!isCriticalPathDisabled);
  };

  const toggleCollapse = () => {
    setIsExpanded(current => {
      if (!isExpanded) {
        ganttRef.current.expandAll();
        setIsExpanded(true);
      } else {
        ganttRef.current.collapseAll();
        setIsExpanded(false);
      }
      return !current;
    });
  };

  const displayTaskFiltered = () => {
    const taskStore = ganttRef.current?.taskStore;
    taskStore?.clearFilters();
    taskStore?.filterBy(createBryntumFilter(displayMyTasks, true, config));
    if (displayMyTasks) {
      ganttRef.current.expandAll();
      setIsExpanded(true);
    } else if (isExpanded) {
      // This will keep expanded the tasks as they were already
      ganttRef.current.expandAll();
    }
  };

  useEffect(() => {
    if (ganttRef.current?.features?.criticalPaths) {
      ganttRef.current.features.criticalPaths.disabled =
        !isCriticalPathDisabled;
    }
  }, [isCriticalPathDisabled]);

  useEffect(() => {
    displayTaskFiltered();
  }, [displayMyTasks]);

  useEffect(() => {
    setDisplayMyTasks(false);
    setIsCriticalPathDisabled(false);
    setIsExpanded(false);
  }, [config]);

  return (
    <div className={styles.bryntumToolBarContainer}>
      <Switch
        checked={isExpanded}
        id="gantt-collapse-all"
        size="small"
        data-test-id="toggle-tasks"
        onClick={toggleCollapse}
      >
        {isExpanded
          ? intl.formatMessage(bryntumToolBarTrad.collapse)
          : intl.formatMessage(bryntumToolBarTrad.expand)}
      </Switch>

      <Switch
        disabled={isDisabled}
        size="small"
        data-test-id="filterMyTask"
        onClick={() => setDisplayMyTasks(!displayMyTasks)}
      >
        {!displayMyTasks
          ? intl.formatMessage(bryntumToolBarTrad.myTasks)
          : intl.formatMessage(bryntumToolBarTrad.allTasks)}
      </Switch>

      {displayCriticalPathSwitch && (
        <Switch
          size="small"
          data-test-id="critical-path-button"
          onClick={toggleCriticalPath}
          checked={isCriticalPathDisabled}
        >
          {isCriticalPathDisabled
            ? intl.formatMessage(bryntumToolBarTrad.criticalPathHide)
            : intl.formatMessage(bryntumToolBarTrad.criticalPathShow)}
        </Switch>
      )}
    </div>
  );
};
