export const addDiscussionIdToLockedFiles = files =>
  files.reduce((acc, { status, links, ...rest }) => {
    if (status === "Locked") {
      const discussionIDObject = links.find(
        link => link.objectTypeName === "Discussion"
      );
      acc.push({
        discussionId: discussionIDObject?.objectId,
        status: status,
        ...rest,
      });
    }
    return acc;
  }, []);
