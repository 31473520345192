/* istanbul ignore file */
import { axiosInstance } from "./axiosInstance";

const PACKAGING_PREFIX = "/api/packaging-systems";
const PACKAGING_COMPONENT_PREFIX = "/api/packaging-components";

export default class PACKAGING extends axiosInstance {
  getPackaging = id => this.instance.get(`${PACKAGING_PREFIX}/${id}`);

  // TODO Should be renamed as getPackagingSystemDetails - should be checked.
  getPackagingFilters = ({
    ownerCompanyId,
    idsIn,
    keysIn,
    versionsIn,
    isLatestOnly,
    allowsUpdateOnly,
    searchText,
    skip,
    take,
  }) =>
    this.instance.post(`${PACKAGING_PREFIX}/collection`, {
      ownerCompanyId,
      idsIn,
      keysIn,
      versionsIn,
      isLatestOnly,
      allowsUpdateOnly,
      searchText,
      skip,
      take,
    });

  searchPackagingComponents = params =>
    this.instance.post(`${PACKAGING_COMPONENT_PREFIX}/collection`, params);
}
